$(document).ready(function () {
    const request_form = window.location.hash.substr(1);

    if(request_form == 'request-form-h') {
        $('.request-form-toggler').click();
        window.history.pushState('', '', window.location.href.replace(/#.*/, ""));
    }
});

$(document).on('click', '.js-section-request-form', function () {
    $('.request-form-toggler').hide();
    $('.request-form-block').show('slow');
    $("html, body").animate({ scrollTop: $('.section-header').height() });
});

$(document).on('click', '.request-form-toggler', function () {
    $(this).hide();
    $('.request-form-block').show('slow');
    $("html, body").animate({ scrollTop: $('.section-header').height() });
});

$(document).on('click', '.btn-close-form', function () {
    $('.request-form-block').hide('slow');
    $('.request-form-toggler').show('slow');

    var form = $('form.request-form');
    $(form).find('input').val('');
    $(form).find('.is-invalid').removeClass('is-invalid');
    $(form).find('.text-error').remove();

    $("html, body").animate({ scrollTop: $('.section-header').height() });
});

$(document).on('click', '.submit-request', function (e) {
    e.preventDefault();
    const self = this;
    const form = $(this).parents('form.request-form');
    $(self).attr('disabled', 'disabled');

    $.ajax({
        type: "POST",
        url: $(form).attr('action'),
        data: form.serialize(),
        success: function (response) {
            if (response.error) { $('.request-form-block').html(response.view); }
            else {
                const request = $('.request-form-block');
                $('.request-form-toggler').show();

                $(request).addClass('closed').hide();
                $(request).find('.is-invalid').removeClass('is-invalid');
                $(request).find('.text-error').remove();
                $(form).find('input').val('');
            }

            window.alert_notification(response);
            $(self).removeAttr('disabled');
        },
        error: function (error) {
            window.alert_notification({error: true, message: error.responseText});
            $(self).removeAttr('disabled');
        }
    });
});