window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support for JavaScript based Bootstrap
 * features such as modals and tabs. This code may be modified to fit the specific needs of your application.
 */
try {
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
} catch (e) {}

/**
 * if jQuery loaded, load  others libraries
 */
window.axios = require('axios');

/**
 *  Init libraries
 */
let token = document.head.querySelector('meta[name="csrf-token"]');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token ? token.content : '';

token
    ? console.info('CSRF has been successfully added to the Axios header.')
    : console.error('CSRF token not found. Help - https://laravel.com/docs/csrf#csrf-x-csrf-token');