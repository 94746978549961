let _window_height = 0;
let _window_offset = 0;
let _scroll_blocked = false;

$(document).ready(function () {
    _window_offset = 0;
    _window_height = $('body').height();

    initAjax();
    addEventListener('resize', windowResize);
});

$(document).scroll(function () {
    init_navbar();
    !_scroll_blocked ? _window_offset = window.pageYOffset : null;
});

$(document).on('click', '.links-box .js-btn-more-subjects', function () {
    $('.col-subject-hidden').toggleClass('hide');
    $('.js-col-more-subjects').toggleClass('hide');
    $('.row-subjects').toggleClass('row-scrolling');
});

$(document).on('click', '.js-btn-more-cities', function () {
    const imgs = $('.row-cities img');
    const city = $('.col-city-hidden');

    $(city).toggleClass('hide');
    $(city).hasClass('hide') ? $(imgs).show() : $(imgs).hide();

    $('.js-col-more-cities').toggleClass('hide');
    $('.row-cities').toggleClass('row-scrolling');
});

$(document).on('click', '.navbar .navbar-toggler', function () {
    var body = $('body');
    var navbar = $('.navbar');
    var navbar_links = $(navbar).find('.links_info-mobile');

    $(navbar).toggleClass('active-panel');
    $(navbar_links).toggleClass('show');
    $(navbar_links).hasClass('show') ? $(body).addClass('scrolling-off') : $(body).removeClass('scrolling-off');
});

$(document).on('click', 'a.link-submit', function (event) {
    event.preventDefault();
    $(this).parents('form').submit();
});

$(document).on('change', '.change-locale', function () {
    ajaxRequest("/locale/change", { locale: this.value }, function(response) {
        if (!response.error) { window.location.reload(); }
    });
});

$(document).on('click', 'a.cmpboxrecalllink', function (event) {
    var btn = $('.cmpboxinner .cmpboxbtnscustomchoices .cmpboxbtnyes');
    var btn_text = $(btn).text();
    var text = btn_text.replace("&nbsp;", " ");

    $(btn).html(text);
});

function windowResize() {
    if($('.links_info-desktop').css('display') !== 'none') {
        var body = $('body');
        var navbar = $('.navbar');
        var navbar_links = $(navbar).find('.links_info-mobile');

        $(navbar_links).removeClass('show');
        $(navbar).removeClass('active-panel');
        $(body).removeClass('scrolling-off');
    }

    init_navbar();

    if(!_scroll_blocked) {
        _scroll_blocked = true;
        setTimeout(window_scroll, 500);
    }
}

window_scroll = function() {
    var window_height_new = $('body').height();
    var window_offset_new = _window_offset * window_height_new / _window_height;

    window.scroll(0, window_offset_new);

    _window_height = window_height_new;
    _window_offset = window_offset_new;
    _scroll_blocked = false;
};

init_navbar = function() {
    let navbar = document.querySelector('.section-header .links_info-desktop');
    let request_form = document.querySelector('.section.request .request-form-block');

    if (navbar) {
        let window_width = window.outerWidth;
        let window_offset = window.pageYOffset;
        let navbar_offset = navbar.offsetTop;

        window_offset > navbar_offset ? $(navbar).addClass('fixed-top') : $(navbar).removeClass('fixed-top');
        window_offset > navbar_offset && !$(request_form).hasClass('closed') ? $(request_form).addClass('fixed-request-form') : $(request_form).removeClass('fixed-request-form');

        if( window_width <= 767) { window_offset > 0 ? $(navbar).addClass('fixed-top').show() : $(navbar).hide(); }
        else { $(navbar).show(); }
    }
};

alert_notification = function(notification) {
    const nav = $('.links_info-desktop');
    const alert = $('.alert-block').clone();
    const type = notification.error ? 'error' : 'success';
    var padding = 5;

    if($(nav).hasClass('fixed-top')) { padding = $(nav).height() + padding; }
    $('.alert-notification').attr('style', 'padding-top: ' + padding + 'px;');

    $(alert).addClass('alert-' + type).removeClass('hide alert-block');
    $(alert).find('.alert-message').text(notification.message);
    $(alert).appendTo('.alert-notification');

    hide_element(alert, 6);
};

trim = function(s, c) {
    c === "]" ? c = "\\]" : null;
    c === "^" ? c = "\\^" : null;
    c === "\\" ? c = "\\\\" : null;
    return s.replace(new RegExp("^[" + c + "]+|[" + c + "]+$", "g"), "");
};

hide_element = function(el, time) {
    let init_time = time;

    let timer = setInterval( function() {
        if(init_time <= 1) { $(el).hide('slow'); }
        if(init_time <= 0) { $(el).remove(); }
        if(init_time <= 0) { clearInterval(timer); }
        init_time -= 1;
    }, 1000);
};

initAjax = function() {
    let token = $('[name="csrf-token"]').attr('content');
    $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': token } });
};

ajaxRequest = function(url, data, callback) {
    const token = $('[name="csrf-token"]').attr('content');

    $.ajax({
        url: url,
        data: data,
        type: "post",
        headers: { 'X-CSRF-TOKEN': token },
        success: function (result) { callback(result && result.response ? result.response : result); },
        error: function (event) {
            let auth = event.getResponseHeader('Requires-Auth');
            let redirect = event.getResponseHeader('Requires-Redirect');

            if (parseInt(auth) === 1 && redirect) { location = redirect; return false; }
            else { callback(event); }
        }
    });
};