let _libraries_loader = 'webpack';

$(window).resize(function() {
    initSwiper();
});

$(document).ready(function () {
    _libraries_loader = window.document.body.getAttribute('data-libraries_loader');

    window.swipers = [];

    initSwiper();
});

$(document).on('click', '.swiper-change-slide', function () {
    var target = this.getAttribute('data-target');
    var type = this.getAttribute('data-type');
    var swiper = window.swipers['swiper_' + target];

    type === 'next' ? swiper.slideNext() : swiper.slidePrev();
});

$(document).on('click', '.link-mode-text', function () {
    const card = $(this).parents('.card');
    $(card).find('.mode-text').toggle('show');
});

function initSwiper() {
    $('.swiper-container').each(function () {
        var mode = this.getAttribute('data-mode');
        var space_between = this.getAttribute('data-space-between');
        var id = this.getAttribute('id');
        var config = getSwiperConfig(mode);

        space_between ? config.spaceBetween = space_between : null;

        window.swipers[id] ? window.swipers[id].destroy() : null;

        if (_libraries_loader === "webpack") {
            window.swipers[id] = new swiper_bundle.Swiper(this, config);
        } else {
            window.swipers[id] = new Swiper(this, config);
        }

    });
}

function getSwiperConfig(mode) {
    var width = $('body').width();
    var _mode = mode ? parseInt(mode) : 1;
    var slides_per_view = 1;

    if(_mode === 1) {
        switch (true) {
            case width <= 499:                  slides_per_view = 1; break;
            case width > 500  && width <= 1200: slides_per_view = 2; break;
            case width > 1200 && width <= 1440: slides_per_view = 3; break;
            case width > 1441:                  slides_per_view = 4; break;
        }
    }

    if(_mode === 2) {
        switch (true) {
            case width <  1150: slides_per_view = 1; break;
            case width >= 1150: slides_per_view = 2; break;
        }
    }

    if(_mode === 3) {
        switch (true) {
            case width > 0   && width <= 830:  slides_per_view = 1; break;
            case width > 830 && width <= 1250: slides_per_view = 2; break;
            case width > 1250:                 slides_per_view = 3; break;
        }
    }

    if(_mode === 4) {
        slides_per_view = 1
    }

    return {
        loop: true,
        centered: true,
        spaceBetween: 30,
        slidesPerView: slides_per_view,
        pagination: { el: '.swiper-pagination' },
        watchOverflow: true
    }
}